module.exports = [
    'No Tone',
    'Unlisted',
    '67.0 Hz',
    '69.3 Hz',
    '71.9 Hz',
    '74.4 Hz',
    '77.0 Hz',
    '79.7 Hz',
    '82.5 Hz',
    '85.4 Hz',
    '88.5 Hz',
    '91.5 Hz',
    '94.8 Hz',
    '97.4 Hz',
    '100.0 Hz',
    '103.5 Hz',
    '107.2 Hz',
    '110.9 Hz',
    '114.8 Hz',
    '118.8 Hz',
    '123.0 Hz',
    '127.3 Hz',
    '131.8 Hz',
    '136.5 Hz',
    '141.3 Hz',
    '146.2 Hz',
    '150.0 Hz',
    '151.4 Hz',
    '156.7 Hz',
    '159.8 Hz',
    '162.2 Hz',
    '165.5 Hz',
    '167.9 Hz',
    '171.3 Hz',
    '173.8 Hz',
    '177.3 Hz',
    '179.9 Hz',
    '183.5 Hz',
    '186.2 Hz',
    '189.9 Hz',
    '192.8 Hz',
    '196.6 Hz',
    '199.5 Hz',
    '203.5 Hz',
    '206.5 Hz',
    '210.7 Hz',
    '218.1 Hz',
    '225.7 Hz',
    '229.1 Hz',
    '233.6 Hz',
    '241.8 Hz',
    '250.3 Hz',
    '254.1 Hz',
    '023 DPL',
    '025 DPL',
    '026 DPL',
    '031 DPL',
    '032 DPL',
    '036 DPL',
    '043 DPL',
    '047 DPL',
    '051 DPL',
    '053 DPL',
    '054 DPL',
    '065 DPL',
    '071 DPL',
    '072 DPL',
    '073 DPL',
    '074 DPL',
    '114 DPL',
    '115 DPL',
    '116 DPL',
    '122 DPL',
    '125 DPL',
    '131 DPL',
    '132 DPL',
    '134 DPL',
    '143 DPL',
    '145 DPL',
    '152 DPL',
    '155 DPL',
    '156 DPL',
    '162 DPL',
    '165 DPL',
    '172 DPL',
    '174 DPL',
    '205 DPL',
    '212 DPL',
    '223 DPL',
    '225 DPL',
    '226 DPL',
    '243 DPL',
    '244 DPL',
    '245 DPL',
    '246 DPL',
    '251 DPL',
    '252 DPL',
    '255 DPL',
    '261 DPL',
    '263 DPL',
    '265 DPL',
    '266 DPL',
    '271 DPL',
    '274 DPL',
    '306 DPL',
    '311 DPL',
    '315 DPL',
    '325 DPL',
    '331 DPL',
    '332 DPL',
    '343 DPL',
    '346 DPL',
    '351 DPL',
    '356 DPL',
    '364 DPL',
    '365 DPL',
    '371 DPL',
    '411 DPL',
    '412 DPL',
    '413 DPL',
    '423 DPL',
    '431 DPL',
    '432 DPL',
    '445 DPL',
    '446 DPL',
    '452 DPL',
    '454 DPL',
    '462 DPL',
    '464 DPL',
    '465 DPL',
    '466 DPL',
    '503 DPL',
    '506 DPL',
    '516 DPL',
    '523 DPL',
    '526 DPL',
    '532 DPL',
    '546 DPL',
    '565 DPL',
    '606 DPL',
    '612 DPL',
    '624 DPL',
    '627 DPL',
    '631 DPL',
    '632 DPL',
    '654 DPL',
    '662 DPL',
    '664 DPL',
    '703 DPL',
    '712 DPL',
    '723 DPL',
    '731 DPL',
    '732 DPL',
    '734 DPL',
    '743 DPL',
    '754 DPL'
];
