<template>
    <v-container>
        <div id="myRepeaters">
            <AdBTWR v-if="!user.isAdFree"/>

            <h1 class="page-header font-weight-light">My Repeaters</h1>

            <div v-if="user.authenticated">
                
                <v-alert border="left" color="info" icon="mdi-information" dense text>
                    If you own a repeater, you can add it to the database by clicking the "Add Repeater" button below. If you are not the owner, please contact the repeater owner to add it to the database.
                </v-alert>

                <v-alert
                    v-model="alerts.confirmDelete.state"
                    type="warning"
                    border="left"
                    icon="mdi-alert"
                    prominent
                    colored-border
                    elevation="3"
                    :class="'mx-5 mt-1 ' + ($vuetify.theme.dark ? 'almostblack' : '')"
                    transition="scale-transition"
                >
                    <v-row align="center">
                        <v-col class="grow">
                            Are you sure you want to permanently delete repeater
                            <strong>{{ alerts.confirmDelete.name }}</strong>?
                        </v-col>
                        <v-col class="shrink">
                            <v-btn @click="cancelDelete" text>Cancel</v-btn>
                        </v-col>
                        <v-col class="shrink">
                            <v-btn color="error" @click="deleteRepeater(alerts.confirmDelete.id)">Delete</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>

                <div class="text-right mb-1 mx-3">
                    <v-btn color="success" @click="addRepeaterDialog">
                        <v-icon class="mr-1">mdi-plus</v-icon>Add Repeater
                    </v-btn>
                </div>

                <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :options.sync="options"
                    :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
                    :server-items-length="total"
                    :mobile-breakpoint="0"
                    class="elevation-0"
                >
                    <template v-slot:item.Name="{ item }">
                        <RepeaterStatusIcon :status="item.Status"/>

                        <router-link :to="'/repeater/' + item.ID">{{ item.Name }}</router-link>
                    </template>

                    <template v-slot:item.Status="{ item }">
                        <v-icon v-if="item.Status === 'Online'" color="success" title="Online" small>mdi-circle</v-icon>
                        <v-icon v-else color="grey darken-1" title="Offline" small>mdi-circle-off-outline</v-icon>
                    </template>

                    <template v-slot:item.Modified="{ item }">
                        <span :title="moment(item.Modified).format('MMM D, YYYY')">{{ item.Modified | fromNow }}</span>
                        <v-icon class="ml-1" color="warning" title="Outdated Listing" small v-if="moment().diff(moment(item.Modified), 'days') > 365">mdi-alert</v-icon>
                    </template>

                    <template v-slot:item.Owner="{ item }">
                        <router-link :to="'/user/' + item.Owner">{{ item.Owner }}</router-link>
                    </template>

                    <template v-slot:item.Frequency="{ item }">
                        {{ item.Frequency }} MHz
                    </template>

                    <template v-slot:item.Actions="{ item }">
                        <div align="right">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="rfaccent2"
                                        v-bind="attrs"
                                        v-on="on"
                                        dark
                                        small
                                    >
                                        Manage
                                        <v-icon class="ml-1">mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item dense @click="editRepeaterDialog(item)">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon color="info">mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title class="info--text">Edit {{ item.Name }}</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item dense @click="confirmDeleteRepeater(item.ID, item.Name)">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon color="error">mdi-delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title class="error--text">Delete {{ item.Name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <!--                                    <v-btn x-small icon color="info" class="mr-5" @click="editRepeaterDialog(item)">-->
                        <!--                                        <v-icon>mdi-pencil</v-icon>-->
                        <!--                                    </v-btn>-->
                        <!--                                    <v-btn x-small icon color="error"-->
                        <!--                                           @click="confirmDeleteRepeater(item.ID, item.Name)">-->
                        <!--                                        <v-icon>mdi-delete</v-icon>-->
                        <!--                                    </v-btn>-->
                    </template>
                </v-data-table>

                <v-dialog
                    v-model="dialogs.repeater.state"
                    fullscreen
                    content-class="dialog-fullscreen-navbar mw-100"
                    attach="#myRepeaters"
                >
                    <v-card>
                        <v-card-title class="headline font-weight-light pb-0">
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogs.repeater.state = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pb-0">
                            <v-container class="pt-0">
                                <h1 class="headline font-weight-light mb-5">
                                    {{ (dialogs.repeater.editMode) ? 'Edit' : 'Add' }} Repeater Listing
                                </h1>

                                <v-alert type="info" border="left" text>
                                    Repeaters may only be listed by the owner of the repeater or an
                                    authorized representative. Posting repeaters of other individuals is a
                                    violation of our
                                    <router-link to="/terms-conditions" class="white--text">Terms and Conditions</router-link>
                                    and may result in disciplinary measures.
                                </v-alert>

                                <v-form ref="repeater" v-model="valid">
                                    <v-row justify="center">
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <input type="hidden" name="">
                                            <v-text-field
                                                v-model="editRepeater.Name"
                                                label="Repeater Name"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.name"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-autocomplete
                                                v-model="editRepeater.Type"
                                                :items="types"
                                                label="Type"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.type"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-text-field
                                                v-model="editRepeater.Location"
                                                label="City"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.location"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-autocomplete
                                                v-model="editRepeater.State"
                                                :items="states"
                                                label="State"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.state"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-autocomplete
                                                v-model="editRepeater.Status"
                                                :items="statuses"
                                                label="Status"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.status"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-autocomplete
                                                v-model="editRepeater.Frequency"
                                                :items="frequencies"
                                                label="Frequency"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.frequency"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-autocomplete
                                                v-model="editRepeater['PL In']"
                                                :items="tones"
                                                label="Input Tone"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.inputTone"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="3" class="pb-0">
                                            <v-autocomplete
                                                v-model="editRepeater['PL Out']"
                                                :items="tones"
                                                label="Output Tone"
                                                autocomplete="off"
                                                outlined
                                                :rules="rules.outputTone"
                                                required
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="6">
                                            <v-slider
                                                v-model="editRepeater.Radius"
                                                label="Estimated Repeater Range"
                                                step="5"
                                                min="0"
                                                max="100"
                                                hint="Enter the estimated range, if known, or select Unknown and enter the HAAT in the next step"
                                                persistent-hint
                                            >
                                                <template v-slot:append>
                                                    <span class="slider-radius-value" v-if="editRepeater.Radius">{{ editRepeater.Radius }} miles</span>
                                                    <span class="slider-radius-value" v-else>Unknown</span>
                                                </template>
                                            </v-slider>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="6">
                                            <v-slider
                                                v-model="editRepeater.HAAT"
                                                label="Height Above Average Terrain (HAAT)"
                                                step="25"
                                                min="25"
                                                max="5000"
                                                hint="How much higher is the repeater antenna than the surrounding elevation?"
                                                persistent-hint
                                                v-if="!editRepeater.Radius"
                                            >
                                                <template v-slot:append>
                                                <span class="slider-radius-value">{{
                                                        editRepeater.HAAT
                                                    }} ft</span>
                                                </template>
                                            </v-slider>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12">
                                            <div class="mb-2">
                                                <v-icon color="info">mdi-information</v-icon>
                                                Place the marker where the repeater is located so others can
                                                find it on the map. Approximate locations are permitted to
                                                conceal the actual repeater location, but the location
                                                should be otherwise accurate.
                                            </div>
                                            <RepeaterMap
                                                :repeater="editRepeater"
                                                height="400"
                                                type="streets-v11"
                                                geocoder
                                                is-draggable
                                                @markerDropped="markerDropped"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <h2 class="title font-weight-light">Repeater Details <small>(i.e.
                                                Rules, Technical Information)</small></h2>
                                            <v-sheet light class="mb-0 elevation-5">
                                                <vue-editor
                                                    v-model="editRepeater.Rules"
                                                    label="Repeater Details and/or Rules"
                                                    :rules="rules.rules"
                                                    required
                                                ></vue-editor>
                                            </v-sheet>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-checkbox
                                                v-model="editRepeater.confirmOwnership"
                                                label="I confirm that I am the legal owner or representative of this repeater and have permission to post it."
                                                :rules="rules.confirmOwnership"
                                                required
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="mb-15">
                            <v-container>
                                <div class="d-flex justify-space-between">
                                    <v-btn
                                        color="error"
                                        @click="dialogs.repeater.state = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="updateRepeater"
                                        :loading="saveRepeaterLoading"
                                    >
                                        Save Repeater
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar
                    v-model="snackbar.state"
                    :color="snackbar.color"
                    :timeout="snackbar.timeout"
                    multi-line
                    top
                >
                    <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
                    {{ snackbar.text }}
                </v-snackbar>
            </div>
            <div v-else>
                <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                    You must be logged in to manage your repeaters.
                </v-alert>

                <Login v-on="$listeners"/>
            </div>

        </div>
    </v-container>
</template>

<style>
    .dialog-fullscreen-navbar {
        margin-top: 64px;
    }

    .slider-radius-value {
        width: 80px;
    }
</style>

<script>
    import config from '../../config';
    import axios from 'axios';
    import moment from 'moment';
    import statusList from '@/utils/statuses';
    import statesList from '@/utils/states';
    import frequencyList from '@/utils/frequencies';
    import tonesList from '@/utils/tones';
    import typesList from '@/utils/repeaterTypes';
    import Login from '@/components/Login.vue';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import RepeaterStatusIcon from '@/components/RepeaterStatusIcon';
    import RepeaterMap from '@/components/RepeaterMap.vue';
    import { VueEditor } from 'vue2-editor';
    import { useTitle } from '@vueuse/core';

    export default {
        name: 'MyRepeaters',

        props: ['user'],

        components: {
            AdBTWR,
            RepeaterStatusIcon,
            RepeaterMap,
            Login,
            VueEditor
        },

        data: () => ({
            tab: null,
            loadingFavorites: false,
            itemsFavorites: [],
            totalFavorites: 0,
            items: [],
            options: {
                sortBy: ['Name'],
                sortDesc: [false],
                itemsPerPage: 0
            },
            total: 0,
            loading: false,
            saveRepeaterLoading: false,
            valid: false,
            rules: {
                name: [v => !!v || 'Repeater Name is required',],
                type: [v => !!v || 'System Type is required',],
                location: [v => !!v || 'City is required',],
                state: [v => !!v || 'State is required',],
                status: [v => !!v || 'Status is required',],
                frequency: [v => !!v || 'Frequency is required',],
                inputTone: [v => !!v || 'Input Tone is required',],
                outputTone: [v => !!v || 'Output Tone is required',],
                rules: [v => !!v || 'Repeater Details is required',],
                confirmOwnership: [v => !!v || 'Repeater Ownership Confirmation is required',],
            },
            editRepeater: {
                ID: null,
                Name: null,
                Location: null,
                State: null,
                Modified: null,
                Frequency: null,
                "PL In": null,
                "PL Out": null,
                Type: null,
                Rules: null,
                Owner: null,
                ORI: null,
                Travel: null,
                Status: null,
                Latitude: null,
                Longitude: null,
                // Network: null,
                Radius: null,
                HAAT: null,
                confirmOwnership: false,
            },
            alerts: {
                confirmDelete: {
                    state: false,
                    id: null,
                    name: null
                }
            },
            dialogs: {
                repeater: {
                    state: false,
                    editMode: false
                }
            },
            snackbar: {
                state: false,
                color: null,
                timeout: 5000,
                text: null
            },
            statuses: statusList,
            states: statesList,
            frequencies: frequencyList,
            tones: tonesList,
            types: typesList,
        }),

        watch: {
            "user.authenticated"() {
                if (this.user.authenticated) {
                    this.fetchRepeaters();
                    this.fetchFavoriteRepeaters();
                }
            },

            search() {
                this.fetchRepeaters();
            },

            frequency() {
                this.fetchRepeaters();
            },

            state() {
                this.fetchRepeaters();
            },

            type() {
                this.fetchRepeaters();
            },

            options: {
                handler() {
                    this.fetchRepeaters();
                    this.fetchFavoriteRepeaters();
                },
                deep: true,
            },
        },

        computed: {
            headers() {
                let headers = [];

                if (this.$vuetify.breakpoint.xsOnly) {
                    headers.push({text: 'Name', value: 'Name', width: 200});
                    headers.push({text: '', value: 'Actions', width: 150, sortable: false});
                } else if (this.$vuetify.breakpoint.smOnly) {
                    headers.push({text: 'Name', value: 'Name', width: 200});
                    headers.push({text: 'City', value: 'Location', width: 175});
                    headers.push({text: 'State', value: 'State', width: 80});
                    headers.push({text: '', value: 'Actions', width: 150, sortable: false});
                } else if (this.$vuetify.breakpoint.mdOnly) {
                    headers.push({text: 'Name', value: 'Name', width: 200});
                    headers.push({text: 'City', value: 'Location', width: 175});
                    headers.push({text: 'State', value: 'State', width: 80});
                    headers.push({text: 'Frequency', value: 'Frequency', width: 120});
                    headers.push({text: '', value: 'Actions', width: 150, sortable: false});
                } else if (this.$vuetify.breakpoint.lgOnly) {
                    headers.push({text: 'Name', value: 'Name', width: 200});
                    headers.push({text: 'City', value: 'Location', width: 175});
                    headers.push({text: 'State', value: 'State', width: 80});
                    headers.push({text: 'Frequency', value: 'Frequency', width: 120});
                    headers.push({text: 'Updated', value: 'Modified', width: 120});
                    headers.push({text: '', value: 'Actions', width: 150, sortable: false});
                } else if (this.$vuetify.breakpoint.xlOnly) {
                    headers.push({text: 'Name', value: 'Name', width: 200});
                    headers.push({text: 'City', value: 'Location', width: 175});
                    headers.push({text: 'State', value: 'State', width: 80});
                    headers.push({text: 'Type', value: 'Type', width: 170});
                    headers.push({text: 'Frequency', value: 'Frequency', width: 120});
                    headers.push({text: 'Updated', value: 'Modified', width: 120});
                    headers.push({text: '', value: 'Actions', width: 150, sortable: false});
                }

                return headers;
            },

            headersFavorites() {
                return this.headers.slice(0, -1);
            }
        },

        methods: {
            markerDropped(data) {
                this.editRepeater.Latitude = data && data.lat ? data.lat : 0;
                this.editRepeater.Longitude = data && data.lng ? data.lng : 0;
            },

            setFavorite(repeaterId, starred) {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios.request({
                    url: config.API_LOCATION + '/favorite/repeater/' + repeaterId,
                    headers: axiosHeaders,
                    method: starred ? 'post' : 'delete'
                })
                    .then(this.fetchFavoriteRepeaters)
                    .catch(err => {
                        console.error(err);
                    });
            },

            fetchRepeaters() {
                this.loading = true;

                let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                let query = config.API_LOCATION + `/repeaters?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc[0]}&offline=true&outdated=true&description=true`;
//                if (this.search) query += `&search=${this.search}`;
//                if (this.frequency) query += '&frequency=' + this.frequency.replace('462', '46X');
//                if (this.state) query += `&state=${this.state}`;
//                if (this.type) query += `&type=${this.type}`;
//                if (this.showOldListings) query += `&outdated=true`;
//                if (this.showOfflineListings) query += `&offline=true`;
                query += '&owner=' + this.user.username;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(query, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.total = (response.data && response.data.info) ? response.data.info.total : 0;
                        this.items = _.map(response.data.items, o => {
                            o.Frequency = o.Frequency.replace('46X', '462');
                            return o;
                        });
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            fetchFavoriteRepeaters() {
                this.loadingFavorites = true;

                let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                let query = config.API_LOCATION + `/favorite/repeaters?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc[0]}&offline=true&outdated=true&description=false`;
//                if (this.search) query += `&search=${this.search}`;
//                if (this.frequency) query += '&frequency=' + this.frequency.replace('462', '46X');
//                if (this.state) query += `&state=${this.state}`;
//                if (this.type) query += `&type=${this.type}`;
//                if (this.showOldListings) query += `&outdated=true`;
//                if (this.showOfflineListings) query += `&offline=true`;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(query, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.totalFavorites = (response.data && response.data.info) ? response.data.info.total : 0;
                        this.itemsFavorites = _.map(response.data.items, o => {
                            o.Frequency = o.Frequency.replace('46X', '462');
                            return o;
                        });
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    })
                    .then(() => {
                        this.loadingFavorites = false;
                    });
            },

            addRepeaterDialog() {
                //set all properties of editRepeater to null
                this.editRepeater = this.$_.mapValues(this.editRepeater, () => null);
                this.dialogs.repeater.editMode = false;
                this.dialogs.repeater.state = true;
            },

            editRepeaterDialog(item) {
                this.editRepeater = this.$_.clone(item);
                this.dialogs.repeater.state = true;
                this.dialogs.repeater.editMode = true;
            },

            confirmDeleteRepeater(id, name) {
                this.alerts.confirmDelete.id = id;
                this.alerts.confirmDelete.name = name;
                this.alerts.confirmDelete.state = true;
            },

            cancelDelete() {
                this.alerts.confirmDelete.id = null;
                this.alerts.confirmDelete.name = null;
                this.alerts.confirmDelete.state = false;
            },

            deleteRepeater(id) {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .delete(config.API_LOCATION + '/repeater/' + id, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.snackbar.state = false;
                            this.snackbar.color = 'success';
                            this.snackbar.icon = 'mdi-check';
                            this.snackbar.text = 'The repeater has been deleted successfully.';
                            this.snackbar.state = true;

                            this.cancelDelete();
                            this.fetchRepeaters();
                        } else {
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = 'Your profile has not been updated due to an error.';
                            this.snackbar.state = true;

                            this.cancelDelete();
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            this.snackbar.state = false;
                            this.snackbar.color = 'error';
                            this.snackbar.icon = 'mdi-alert';
                            this.snackbar.text = 'The repeater could not be deleted due to an error.';
                            this.snackbar.state = true;

                            this.cancelDelete();
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            updateRepeater() {
                this.$refs.repeater.validate();

                if (!this.valid) {
                    this.snackbar.text = 'Please fill out all required fields or correct any errors and try again.';
                    this.snackbar.icon = 'mdi-alert';
                    this.snackbar.color = 'error';
                    this.snackbar.state = true;
                } else {

                    //handle toggling of the radius and haat based on which is set -- radius has precedence
                    if (this.editRepeater.Radius > 0) this.editRepeater.HAAT = null;
                    else this.editRepeater.Radius = null;

                    //apply some defaults
                    if (this.$_.isNull(this.editRepeater.Status)) this.editRepeater.Status = 'Online';
                    if (this.$_.isNull(this.editRepeater.ORI)) this.editRepeater.ORI = 'No';
                    if (this.$_.isNull(this.editRepeater.Travel)) this.editRepeater.Travel = 'No';

                    //ensure we don't pass the network and node value through from an existing repeater
                    delete this.editRepeater.Network;
                    delete this.editRepeater.Node;
                    delete this.editRepeater.confirmOwnership;

                    this.editRepeater.Owner = this.user.username;
                    // this.editRepeater.Modified = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                    this.editRepeater.Modified = moment.utc();
                    this.editRepeater.Latitude = this.$_.isNumber(this.editRepeater.Latitude) ? parseFloat(this.editRepeater.Latitude.toFixed(10)) : 0;
                    this.editRepeater.Longitude = this.$_.isNumber(this.editRepeater.Longitude) ? parseFloat(this.editRepeater.Longitude.toFixed(10)) : 0;

                    //sanity check the location to make sure it's not at the starting point @todo later on, this should be added on click so we can force the user to choose a spot!
                    if (this.editRepeater.Latitude === 0 && this.editRepeater.Longitude === 0) {
                        this.snackbar.text = 'Please select a location on the map by dragging the icon to the appropriate location.';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.color = 'error';
                        this.snackbar.state = true;
                    } else {
                        this.saveRepeaterLoading = true;

                        let axiosHeaders = {};
                        if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                        if (this.$_.isUndefined(this.editRepeater.ID) || this.$_.isNull(this.editRepeater.ID)) {
                            delete this.editRepeater.ID;
                            axios
                                .post(config.API_LOCATION + '/repeater', this.editRepeater, {
                                    headers: axiosHeaders
                                })
                                .then(response => {
                                    console.log(response.data);

                                    this.dialogs.repeater.state = false;
                                })
                                .catch(err => {
                                    //show error snackbar
                                    if (err.response && err.response.status === 401) {
                                        this.$emit('unauthorized');
                                    } else {
                                        //show error snackbar
                                        this.snackbar.color = 'error';
                                        this.snackbar.text = err.response.data.message || 'An error has occurred. Please check that you filled everything in and try again.';
                                        this.snackbar.icon = 'mdi-alert';
                                        this.snackbar.state = true;
                                    }
                                })
                                .then(() => {
                                    this.saveRepeaterLoading = false;
                                    this.fetchRepeaters();
                                });
                        } else {
                            axios
                                .put(config.API_LOCATION + '/repeater/' + this.editRepeater.ID, this.editRepeater, {
                                    headers: axiosHeaders,
                                })
                                .then(response => {
                                    console.log(response.data);

                                    this.dialogs.repeater.state = false;
                                })
                                .catch(err => {
                                    if (err.response && err.response.status === 401) {
                                        this.$emit('unauthorized');
                                    } else {
                                        //show error snackbar
                                        this.snackbar.color = 'error';
                                        this.snackbar.text = err.response.data.message || 'An error has occurred. Please check that you filled everything in and try again.';
                                        this.snackbar.icon = 'mdi-alert';
                                        this.snackbar.state = true;
                                    }
                                })
                                .then(() => {
                                    this.saveRepeaterLoading = false;
                                    this.fetchRepeaters();
                                });
                        }
                    }
                }
            },

            moment(date) {
                return moment(date);
            }
        },

        filters: {
            fromNow(date) {
                return moment(date).fromNow();
            }
        },

        mounted() {
            useTitle('My Repeaters - myGMRS.com');
        }
    }
</script>
